<template>
  <div class="center">
    <div class="bg">
      <img :src="Bg" class="img" alt="" srcset="" />
    </div>

    <div class="operate-wrap">
      <div class="content" v-if="!userInfo.user_id">
        <input
          type="text"
          v-model="phoneNum"
          placeholder="请输入您的手机号码"
        />
        <div class="btn" @click="fetchUserInfo">确定</div>
      </div>
      <div v-else class="content">
        <div class="user">
          <img class="avatar" :src="userInfo.avatar" alt="" srcset="" />
          <span style="margin-left: 8px">{{ userInfo.nickname }}</span>
        </div>

        <div class="btn" @click="resetUserInfo">切换账号</div>
      </div>
    </div>

    <div class="charge-area">
      <div class="switch-btn">
        <div @click="switchBtnHandler('vip')">
          <span class="title">开通VIP</span>
          <span v-show="buttonStatus === 'vip'" class="divide"></span>
        </div>
        <div @click="switchBtnHandler('coin')">
          <span class="title">金币充值</span>
          <span v-show="buttonStatus === 'coin'" class="divide"></span>
        </div>
      </div>
      <div v-show="buttonStatus === 'vip'" class="container">
        <charge-card
          v-for="(item, index) in vipList"
          :key="index"
          @select="selectVipHandler($event, item)"
          :select="item.selected"
          :limit="item.limit.toString()"
          :limit-unit-text="item.limitUnitText"
          :discount="item.discount.toString()"
          :currency="item.currency"
          :price="item.price.toString()"
          :has-partner-icon="item.hasPartnerIcon"
          :hasTasteVipIcon="item.hasTasteVipIcon"
        ></charge-card>
      </div>
      <div v-show="buttonStatus === 'coin'" class="container-coin">
        <div v-for="(item, index) in coinList" :key="index">
          <coin-card
            :selected="item.selected"
            :coin-num="item.num.toString()"
            :currency="item.currency"
            :price="item.price.toString()"
            @select="selectCoinHandler($event, item)"
          ></coin-card>
        </div>
      </div>
    </div>

    <div class="charge-btn-wrap">
      <div class="charge-btn" @click="charge">确认充值</div>
    </div>
    <overlay v-model="isError" :title="errorMsg"></overlay>
    <overlay v-model="isPaySuccess" title="支付成功"></overlay>
  </div>
</template>

<script>
import ChargeCard from "./components/charge-card.vue";
import overlay from "./components/overlay.vue";
import CoinCard from "./components/coin-card.vue";
import axios from "axios";
import {
  getLoginInfo,
  getCode,
  getUserInfo,
  getRechargeOption,
  CreateOrder,
  OrderStatus,
  getOrderStatus,
} from "./api";
export default {
  name: "RechargeCenter",
  components: { ChargeCard, overlay, CoinCard },
  data() {
    return {
      Bg: require("@/assets/bg.png"),
      phoneNum: "",
      userInfo: {
        avatar: "",
        nickname: "",
        phone_number: "",
        user_id: null,
      },
      coinList: [],
      vipList: [],
      isError: false,
      isPaySuccess: false,
      buttonStatus: "vip",
      errorMsg: "",
    };
  },
  methods: {
    selectVipHandler(_, info) {
      this.resetStatus(this.vipList, "selected");
      info.selected = !info.selected;
      this.vipList = [...this.vipList];
    },
    switchBtnHandler(type) {
      this.buttonStatus = type;

      this.resetStatus(this.vipList, "selected");
      this.resetStatus(this.coinList, "selected");
    },
    selectCoinHandler(_, info) {
      this.resetStatus(this.coinList, "selected");
      info.selected = !info.selected;
      this.coinList = [...this.coinList];
    },
    resetStatus(list, key, status = false) {
      list.forEach((item) => {
        item[key] = status;
      });
    },
    setCookie(name, value, d) {
      let dateo = new Date(); //获取当前时间对象
      let day = dateo.getDate(); //获取当前的日期-1值
      dateo.setDate(day + d); //修改当前值为d天后
      document.cookie =
        name + "=" + encodeURI(value) + "; expires=" + dateo.toGMTString();
      //设置cookie,expires为过期日期，设置的格式应该是"name=value; expires=过期日期"
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
    },
    fetchRechargeOptions() {
      getRechargeOption({ type: 1 }).then((res) => {
        this.vipList = res.items.map((item) => ({
          ...item,
          limit: item.goods,
          limitUnitText: item.goods_desc,
          price: item.amount,
          hasPartnerIcon: item.goods >= 12 ? true : false,
          hasTasteVipIcon: item.goods == 7 ? true : false,
          discount: item.before_amount === 0 ? "" : item.before_amount,
          id: item.id,
          currency: item.currency_symbol,
        }));
      });
      getRechargeOption({ type: 2 }).then((res) => {
        this.coinList = res.items.map((item) => ({
          ...item,
          num: item.goods,
          price: item.amount,
        }));
      });
    },
    fetchUserInfo() {
      getUserInfo({ phone_number: this.phoneNum })
        .then((res) => {
          if (res.errorCode !== 0) {
            this.isError = true;
            this.errorMsg = res.errorMsg;
          } else {
            this.userInfo = res.data;
          }
        })
        .catch((err) => {
          this.isError = true;
        });
    },
    charge() {
      if (this.userInfo != null && this.userInfo.user_id > 0) {
        let optionId = this.getOptionId();
        if (optionId == 0) {
          this.isError = true;
          if (this.buttonStatus == 'vip') {
            this.errorMsg = '请选择会员时长';
          } else {
            this.errorMsg = '请选择充值金额';
          }
        } else {
          CreateOrder({
            user_id: this.userInfo.user_id,
            order_type: this.getOrderType(),
            option_id: this.getOptionId(),
          }).then((res) => {
            console.log("res", res);
            this.invokeWechatPay(res.wechat_pay_result, res.order_no);
          });
        }
      } else {
        this.isError = true;
        this.errorMsg = '请输入您的手机号！';
      }
    },

    getOrderType() {
      const dict = {
        vip: 1,
        coin: 2,
      };
      return dict[this.buttonStatus];
    },
    getOptionId() {
      const list = this[`${this.buttonStatus}List`];
      const result = list.filter((item) => item.selected);
      if (result[0] && result[0].id) {
        return result[0].id;
      } else {
        return 0;
      }
    },
    resetUserInfo() {
      this.userInfo = {
        avatar: "",
        nickname: "",
        user_id: null,
        phone_number: "",
      };
      this.phoneNum = "";
    },
    invokeWechatPay(params, orderNo) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady(params, orderNo);
      }
    },
    onBridgeReady(params, orderNo) {
      const userId = this.userInfo.user_id;
      function fetchOrder(timeId) {
        getOrderStatus({
          user_id: userId,
          order_no: orderNo,
        }).then((orderStatus) => {
          // pay_status  1=未支付 2=已支付
          if (orderStatus.pay_status === 2) {
            this.isPaySuccess = true;
            clearInterval(timeId);
          }
        });
      }
      const { loop } = this.pollingFn(fetchOrder.bind(this), 19, true);
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",

        {
          appId: params.appid, //公众号ID，由商户传入
          timeStamp: params.timestamp.toString(), //时间戳，自1970年以来的秒数
          nonceStr: params.noncestr, //随机串
          package: params.package,
          signType: params.signType, //微信签名方式：
          paySign: params.paySign,
        },
        (res) => {
          // loop();
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.isPaySuccess = true;
            // window.location.href=''
          }
        }
      );
    },

    pollingFn(fn, t, immediate) {
      let timers = t;
      let id = null;
      function loop() {
        if (immediate) {
          fn(id);
        }
        id = setInterval(() => {
          if (timers > 0) {
            fn(id);
          } else {
            clearInterval(id);
          }
          timers -= 1;
        }, 1000);
      }
      function cancel() {
        clearInterval(id);
      }
      return {
        loop,
        cancel,
      };
    },

    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      let r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return decodeURIComponent(r[2]);
      return null; //返回参数值
    },
  },
  created() {
    document.title = "简单会员充值";
    this.isPaySuccess = false
  },

  mounted() {
    let code = this.getUrlParam("code");
    if (
      this.getCookie("sess") != null &&
      this.getCookie("sess") != "undefined"
    ) {
      this.fetchRechargeOptions();
    } else {
      if (code != null) {
        getLoginInfo({ code: code }).then((res) => {
          let sess = res.sess;
          this.setCookie("sess", sess);
          this.fetchRechargeOptions();
        });
      } else {
        getCode().then((res) => {
          window.location.href = res.url;
        });
      }
    }
  },
};
</script>

<style lang="scss">
.bg {
  width: 750px;
  height: 393 * $vh;
  img {
    width: 100%;
    height: 100%;
  }
}
.operate-wrap {
  position: absolute;
  margin-top: -51px;
  width: 100%;
  height: 130 * $vh;
  background-color: #ffffff;
  z-index: 9999;
  border-radius: 20px 20px 0 0;
  .content {
    @include flex;
    padding: 0 $padding;
    margin-top: 36px;
    justify-content: space-between;
    input {
      outline: none;
      border: none;
      font-size: 28px;
      width: 460px;
      height: 82 * $vh;
      background: #f5f5f5;
      border-radius: 12px;
      padding: 0 22px;
    }
    .btn {
      width: 167px;
      height: 78 * $vh;
      background: #f0daa4;
      border: 2px solid #dcba73;
      border-radius: 22px;
      color: #b08b43;
      @include flex-center;
    }
    .avatar {
      border-radius: 50%;
      height: 70 * $vh;
      width: 70px;
    }
    .user {
      @include flex-center;
    }
  }
}
.charge-area {
  margin-top: 60px;
  .switch-btn {
    @include flex;
    font-size: 29px;
    font-weight: bold;
    color: #000000;
    line-height: 36px;
    justify-content: space-around;
    padding: 46px 140px 30px 140px;
  }
  .container-coin {
    padding: 0 22px;
    display: grid;
    grid-template-columns: repeat(3, 202px);
    grid-template-rows: repeat(3, 132px);
    grid-gap: 40px 50px;
  }
  .container {
    padding: 0 22px;
    display: grid;
    grid-template-columns: repeat(3, 202px);
    grid-template-rows: repeat(2, 240px);
    grid-gap: 50px;
  }
}
.divide {
  display: flex;
  height: 10 * $vh;
  background-color: #ff2989;
  margin: 17px 20px;
  border-radius: 5px;
}
.charge-btn-wrap {
  @include flex-center;
  margin-top: 50px;
  .charge-btn {
    @include flex-center;
    width: 100%;
    margin: 0px 40px;
    height: 78 * $vh;
    background: linear-gradient(85deg, #ff499a, #ff3644);
    border-radius: 39px;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
